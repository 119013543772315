import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import DiscordIcon from '../utils/DiscordIcon';
import TwitterIcon from '../utils/TwitterIcon';
import { t } from 'i18next';

/* eslint-disable @next/next/no-img-element */
const OtherContact = () => {
  const pathname = usePathname();
  return (
    <div className="text-center">
      <div>Follow us on</div>
      <div className="flex justify-center" style={{ fontSize: '1.75rem' }}>
        <Link
          aria-label="twitter"
          target="_blank"
          href="https://twitter.com/truechatdata"
          className="mx-2 button-link"
        >
          <TwitterIcon className="inline-block" />
        </Link>
        <Link
          aria-label="discord"
          target="_blank"
          href="https://discord.gg/2XmKDHpMZn"
          className="mx-2 button-link"
        >
          <DiscordIcon className="inline-block" />
        </Link>
      </div>
      <div className="flex-col md:flex-row flex gap-2 justify-center mb-2 items-center">
        {pathname === '/community' ? (
          <div className="badge-placeholder">
            <div className="absolute top-0 left-0">
              <Link
                href="https://www.theinsaneapp.com/tools/medical-chat?utm_source=medical-chat_embed"
                target="_blank"
                style={{ width: '250px', height: '54px' }}
              >
                <Image
                  loading="lazy"
                  className="inline-block"
                  src="/images/Featured On TheInsaneApp.png"
                  alt="Medical Chat | Featured on TheInsaneApp"
                  width={250}
                  height={54}
                  decoding="async"
                  data-nimg="1"
                />
              </Link>
            </div>
          </div>
        ) : null}

        <div className="badge-placeholder">
          <div className="absolute top-0 left-0">
            <Link
              href="https://theresanaiforthat.com/ai/medical-chat/?ref=featured&v=417476"
              target="_blank"
              rel="nofollow"
            >
              <Image
                src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
                alt="Featured on There's an AI for That"
                width={300}
                height={60}
              />
            </Link>
          </div>
        </div>

        <div className="badge-placeholder">
          <div className="absolute top-0 left-0">
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="65bff747f750ea13d1558ea7"
              data-style-height="52px"
              data-style-width="100%"
            >
              <Link
                href="https://www.trustpilot.com/review/medical.chat-data.com"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Trustpilot
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = React.memo(function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="text-center">
      <hr className="mb-3"></hr>
      <div>
        <p className="mt-2">
          {t('contact_email_msg_prefix')}
          <Link
            href="mailto:admin@chat-data.com"
            rel="noreferrer"
            target="_blank"
          >
            {t('contact_email_msg_suffix')}
          </Link>
          .
        </p>
        <OtherContact />
        <p>
          <Link href="/privacy">{t('footer_privacy')}</Link>
          &nbsp;•&nbsp;
          <Link href="/service">{t('footer_service')}</Link>
        </p>
      </div>
      <div className="mx-auto container px-2 mt-1">
        <p className="text-sm text-center">{t('copyright', { date: year })}</p>
        <p className="text-xs text-center text-slate-600">
          {' '}
          {t('footer_disclaimer')}
        </p>
      </div>
    </footer>
  );
});
export default Footer;
